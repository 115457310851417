<template>
    <b-card no-body class="tab-layout">
        <h5
            class="text-center mb-0 px-3 py-3 over-hover"
            v-if="showMainHeader && activeTableRef && activeTableRef.table"
        >
            <span v-html="activeTableRef.table.header"></span>
        </h5>

        <b-tabs v-model="activeTab" v-bind="computedTabProps">
            <b-tab
                v-bind="tab.options"
                :key="tab.__key"
                v-for="(tab, index) in computedTabs"
                @click="onTabClick(index, tab)"
            >
                <template #title>
                    {{ tab.title }}
                </template>

                <keep-alive>
                    <page-renderer v-if="tab.type === 'page' && isTabActive(index)" :name="getTabName(tab)" />
                    <platon-table
                        v-else-if="tab.type === 'table' && isTabActive(index)"
                        ref="tables"
                        :table-name="getTabName(tab)"
                        @tableLoaded="(t) => onTableLoaded(index, t)"
                        @onFiltersChange="(filters) => onTableFilterChanged(index, filters)"
                        :table-filters="getFiltersFor(index)"
                        :external-filters="filtersAndButtons"
                        :show-header="showHeader"
                        :use-external-buttons="useExternalButtons"
                        :use-external-filters="useExternalFilters"
                        :control-buttons="true"
                        :pagination-enabled="true"
                        :show-filters="true"
                        :draw="index === activeTab"
                    />
                </keep-alive>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { isObjDiff } from "@Platon/core/helpers"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { DevLog } from "@Platon/const"
import PageRenderer from "@Platon/components/pages/PageRenderer.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"

const separateFilterNames = ["_page", "_level"]

/** @typedef MultiTabOptions
 * @property {string|function} name
 * @property {string} type
 * @property {string} title
 * @property {boolean} main
 * @property {?Function} canRender
 * @property {?Function} onTabClick
 */

/**
 * @property {Vue.Component[]} $refs.tables
 */
export default {
    name: "MultiTab",
    components: { PageRenderer },
    mixins: [NavigationMixin, PermissionMixin],

    props: {
        /**
         * @type MultiTabOptions[]
         */
        tabs: {
            type: Array
        },

        tabProps: {},

        showHeader: {
            type: Boolean,
            default: false
        },

        showMainHeader: {
            type: Boolean,
            default: false
        },

        separateFilters: {
            type: Boolean,
            default: false
        },

        useExternalButtons: {
            type: Boolean,
            default: false
        },

        useExternalFilters: {
            type: Boolean,
            default: false
        }
    },

    data() {
        let mainTabIndex = this.tabs.findIndex(/** MultiTabOptions */ (t) => t.main === true)
        let openTabFromRoute = this.tabs.findIndex(
            /** MultiTabOptions */ (t) => {
                return this.getTabName(t) === this.$route.query.__tab && t.type === this.$route.query.__type
            }
        )

        let activeTab = openTabFromRoute >= 0 ? openTabFromRoute : mainTabIndex

        let filters = { ...this.$route.query }

        return {
            filters,
            activeTab: activeTab,
            mainTabIndex: mainTabIndex,
            mainTab: this.tabs[mainTabIndex],
            filtersAndButtons: [],
            mainTableRef: null,
            activeTableRef: null
        }
    },

    mounted() {
        this.registerLinkHandler(this.tableLinkHandler)
    },

    beforeDestroy() {
        this.unregisterLinkHandler()
    },

    methods: {
        /**
         * @param {Number} index
         * @param {Vue.Component} table
         */
        onTableLoaded(index, table) {
            this.updateMainHeader()

            if (this.mainTabIndex !== index) {
                return
            }

            this.mainTableRef = table
            this.filtersAndButtons = table.filtersAndButtons
        },

        /**
         * @param {number} index - table index
         */
        getFiltersFor(index) {
            let filters = { ...(index === this.mainTabIndex ? this.mainFilters : this.filters) }
            let tableName = this.getTabName(this.tabs[index])

            if (this.separateFilters) {
                for (let filter of separateFilterNames) {
                    // _level_farmers, _page_farmers
                    if (filters[`${filter}_${tableName}`]) {
                        filters[filter] = filters[`${filter}_${tableName}`]
                    }
                }
            }

            return filters
        },

        onFiltersChange(filters) {
            // respect current tab active tab
            this.changeFiltersForTab(this.activeTab, filters)
        },

        onTableFilterChanged(tabIndex, filters) {
            if (tabIndex === this.activeTab) {
                this.changeFiltersForTab(tabIndex, filters)
            }
        },

        /**
         * @param {number} tabIndex
         * @param {object} filters
         */
        changeFiltersForTab(tabIndex, filters) {
            DevLog("Table filters changed", filters)

            let newFilters = { ...filters }
            let activeTab = this.tabs[tabIndex]

            if (this.separateFilters) {
                for (let filter of separateFilterNames) {
                    if (newFilters[filter]) {
                        newFilters[`${filter}_${this.getTabName(activeTab)}`] = newFilters[filter]

                        delete newFilters[filter]
                    }
                }
            }

            this.handleFilterChange({ ...newFilters, __tab: this.getTabName(activeTab), __type: activeTab.type })
        },

        handleFilterChange(filters) {
            if (Object.keys(filters).length === 0) return

            if (isObjDiff(filters, this.filters)) {
                this.$set(this, "filters", filters)

                this.changeActiveTabIfNeeded()

                this.$router.push({
                    query: filters
                })
            }
        },

        changeActiveTabIfNeeded() {
            let currentTab = this.activeTab
            let tabFromFilters = this.tabs.findIndex(
                /** MultiTabOptions */ (t) => {
                    return this.getTabName(t) === this.filters.__tab && t.type === this.filters.__type
                }
            )

            if (currentTab !== tabFromFilters) {
                this.activeTab = tabFromFilters
            }
        },

        /**
         * @param {Route} route
         */
        tableLinkHandler(route) {
            if (
                route.name === "page" &&
                route.params.name === this.$route.params.name &&
                this.$route.query.__tab === this.activeTabName
            ) {
                this.onFiltersChange(route.query)

                return true
            } else if (route.name === "table" && route.params.name === this.activeTabName) {
                this.onFiltersChange(route.query)

                return true
            }
        },

        updateMainHeader() {
            this.$nextTick(() => {
                this.activeTableRef = this.$refs.tables.find((t) => t.tableName === this.activeTabName)
            })
        },

        isTabActive(index) {
            return index === this.activeTab || index === this.mainTabIndex
        },

        /**
         * @param {MultiTabOptions} tab
         */
        canRenderTab(tab) {
            if (typeof tab.canRender === "function") {
                return tab.canRender.call(this, tab)
            }

            return true
        },

        /**
         * @param {number} index
         * @param {MultiTabOptions} tab
         */
        onTabClick(index, tab) {
            if (typeof tab.onTabClick === "function") {
                tab.onTabClick.call(this, tab, index)
            }
        },

        /**
         * @param {MultiTabOptions} tab
         */
        getTabName(tab) {
            if (typeof tab.name === "function") {
                return tab.name.call(this, tab)
            }

            return tab.name
        }
    },

    computed: {
        /**
         * Using to prevent loading main table unnecessarily
         */
        mainFilters() {
            if (this.filters.__tab !== this.getTabName(this.mainTab) && this.filters.__type !== this.mainTab.type) {
                return { ...this.filters, __tab: this.getTabName(this.mainTab), __type: this.mainTab.type }
            } else {
                return {
                    ...this.filters,
                    __tab: this.getTabName(this.tabs[this.activeTab]),
                    __type: this.tabs[this.activeTab].type
                }
            }
        },

        activeTabName() {
            return this.getTabName(this.tabs[this.activeTab])
        },

        computedTabProps() {
            return Object.assign({ justified: true, card: true }, { ...this.tabProps })
        },

        computedTabs() {
            return this.tabs
                .map(
                    /** MultiTabOptions */ (tab, index) => {
                        return {
                            ...tab,
                            __key: tab.type + tab.name.toString() + index
                        }
                    }
                )
                .filter((/** MultiTabOptions */ t) => this.canRenderTab(t))
        }
    },

    watch: {
        activeTab(active) {
            // respect current active tab
            this.handleFilterChange({
                ...this.filters,
                __tab: this.getTabName(this.tabs[active]),
                __type: this.tabs[active].type
            })

            this.updateMainHeader()
        },

        $route(current, old) {
            // respect tab from route
            this.onFiltersChange({
                __tab: this.getTabName(this.tabs[this.activeTab]),
                __type: this.tabs[this.activeTab].type,
                ...current.query
            })
        }
    }
}
</script>
